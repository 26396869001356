import { Link } from "react-scroll";
import logo from '.././img/IAlogo.png'
import '../styles/navbar.css';
function Navbar() {
  document.addEventListener("scroll", function (e) {
    if (window.screen.width < 768 && window.scrollY > 690) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");
      nav.classList.add("navopened");
    } else if (window.screen.width > 768 && window.scrollY > 220) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");
      nav.classList.add("navopened");
    } else {
      const nav = document.querySelector(".navbar");
      const gotop = document.querySelector(".gotop");
      gotop.classList.remove("display");
      nav.classList.remove("navopened");
    }
  });

  function openBar() {
    const bar = document.querySelector(".bar");
    bar.classList.toggle("opened");
  }

  return (
    <nav className="navbar">
      <div className="container">
        <div className="row">
          <div className="logo">
            <img src={logo} alt='' className='logo-image'/>
            <Link
              spy={true}
              smooth={true}
              duration={1000}
              to="video-container"
              style={{ cursor: "pointer" }}
            >
              INFINITE ALGOS
            </Link>
          </div>
          <ul className="bar">
            <li>
              <Link
                onClick={openBar}
                activeClass="active"
                spy={true}
                smooth={true}
                duration={1000}
                to="video-container"
              >
                Home
              </Link>
            </li>
            
            <li>
              <Link
                onClick={openBar}
                to="hiring"
                spy={true}
                smooth={true}
                duration={1000}
                activeClass="active"
              >
                Hiring
              </Link>
            </li>
            <li>
              <Link
                onClick={openBar}
                activeClass="active"
                to="services-container"
                spy={true}
                smooth={true}
                duration={1000}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                onClick={openBar}
                to="about"
                spy={true}
                smooth={true}
                duration={1000}
                activeClass="active"
              >
                About
              </Link>
            </li>

            <li>
              <Link
                onClick={openBar}
                to="full-screen-container"
                spy={true}
                smooth={true}
                duration={1000}
                activeClass="active"
              >
                Contact
              </Link>
            </li>
            <li>
              <Link
                onClick={openBar}
                activeClass="active"
                to="team-section"
                spy={true}
                smooth={true}
                duration={1000}
              >
                Team
              </Link>
              </li>
              <li>
              <Link
                onClick={openBar}
                activeClass="active"
                to="director-container"
                spy={true}
                smooth={true}
                duration={1000}
              >
                Directors
              </Link>
            </li>
          </ul>
          <div className="button" onClick={openBar}>
            <div className="burger"></div>
            <div className="burger"></div>
            <div className="burger"></div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
